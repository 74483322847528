<template>
    <div class="col-12 text-center">
        <div class="maximum-width">
            <template v-if="notEnoughData || depositError || minDepositReached || maxDepositReached">
                <!--Error-->
                <error></error>
                <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
                <p v-else-if="depositError" class="alert alert-danger mt-5 text-center">{{depositError}} {{$t('request-unsuccessful')}}</p>
                <p v-else-if="minDepositReached" class="alert alert-danger mt-5 text-center">{{$t('min-deposit-reached')}} {{$t('request-unsuccessful')}}</p>
                <p v-else-if="maxDepositReached" class="alert alert-danger mt-5 text-center">{{$t('max-deposit-reached')}} {{$t('request-unsuccessful')}}</p>
            </template>
            <div v-else-if="showLoader">
                <div class="text-center mt-5">
                    <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div><br/>
                </div>
            </div>
            <div v-else-if="(limitReached && maxDeposit > 0)">

              <form @submit.prevent="submit" novalidate>
                    <limits-amount v-on:playerAmount="playerAmount" :amount="amount" :playerCurrencySymbol="playerCurrencySymbol" :limitReached="limitReached" :minDeposit="minDeposit" :maxDeposit="maxDeposit"></limits-amount>
                    <button type="button" @click="submit()" class="btn btn-success col-12 mt-3" :class="{'disabled':submitStatus === 'PENDING'}">
                        <div v-if="submitStatus !== 'PENDING'"><strong>{{$t('submit')}}</strong></div>
                        <div v-else class="d-flex align-items-center">
                            <strong>{{$t('loading')}}...</strong>
                            <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
                        </div>
                    </button>
                </form>
            </div>

        </div>
    </div>
</template>

<script>
    import {getProviderUrl} from "../../services/backend.service";
    import {validURL} from "../../helpers/helpers";

    export default {
        name: "RedirectDeposit",
        props:{
            playerCurrency: String,
            amount : Number,
            processorName: String,
            processorType: String,
            walletType : String,
            customerId: String,
            ipAddress: String,
            language: String,
            brandUrl: String,
            playerCurrencySymbol: String,
            minDeposit: String,
            maxDeposit: String,
        },
        data() {
            return {
                notEnoughData: null,
                depositError: null,
                showLoader:true,
                limitReached: null,
                submitStatus: null,
                minDepositReached: null,
                maxDepositReached: null,
            }
        },
        components: {
            Error: () => import('./Error'),
            LimitsAmount: () => import('./LimitsAmount'),

        },
        mounted() {
            this.redirectDeposit();
        },
        methods: {
            redirectDeposit(formSubmit) {
                if(!this.ipAddress || !this.playerCurrency || !this.amount || !this.customerId){
                        this.notEnoughData = true;
                        return;
                }

                debugger;

                if(!this.minDeposit || !this.maxDeposit){
                    this.minDeposit = "5"
                    this.maxDeposit = "1000000"

                    return;
                }

                if (this.amount < this.minDeposit){
                    this.minDepositReached = true;
                    // this.depositError = "Minimum deposit exceeded. Please enter a higher amount "
                    return;
                }

                if (this.amount > this.maxDeposit){
                    // this.depositError = "Maximum deposit exceeded. Please enter a lower amount "
                    this.maxDepositReached = true;
                    return;
                }

                const amount = formSubmit ? this.amount * 100 : this.amount;
                getProviderUrl({
                    currency: this.playerCurrency,
                    amount: amount,
                    processorName: this.processorName,
                    processorType: this.processorType,
                    walletType: this.walletType,
                    customerId: this.customerId,
                    ipAddress: this.ipAddress,
                    language: this.language,
                    brandUrl: this.brandUrl
                }).then(res => {
                    this.showLoader = false;
                    this.submitStatus = null;
                    if (res.data && res.data.success && res.data.data && res.data.data.redirectUrl) {
                        if (validURL(res.data.data.redirectUrl)){
                            window.location = res.data.data.redirectUrl;
                        }
                        else{
                            this.showLoader = false;
                            this.depositError = this.$t('error-2');
                        }


                    } else if(res.data && res.data.success && res.data.data &&
                        res.data.data.minLimitAmount != null && res.data.data.maxLimitAmount != null &&
                        res.data.data.errorCode &&
                        res.data.data.errorCode !== 'account-invalid') {

                        this.minDeposit = Math.floor(res.data.data.minLimitAmount/100);
                        this.maxDeposit = Math.floor(res.data.data.maxLimitAmount/100);

                        if (this.maxDeposit === 0){
                            this.depositError = this.$t(res.data.data.errorCode);
                            return;
                        }

                        this.amount = Math.floor(res.data.data.maxLimitAmount/100);
                        this.limitReached = this.$t(res.data.data.errorCode);
                    } else {
                        this.showLoader = false;
                        this.depositError = this.$t('error-2');
                    }
                }).catch(e => {
                    this.showLoader = false;
                    this.depositError = this.$t('error-2');
                    console.log(`%c Error in getting url - method: RedirectDeposit - ${e}`, 'color:red');
                });
            },
            submit() {
                this.submitStatus = 'PENDING';
                this.redirectDeposit(true);
            },
            playerAmount(value){
                this.amount = value;
            }
        }
    }
</script>

<style scoped>

</style>